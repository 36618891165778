import React from "react";
import AutoCarousel from "../commons/auto-carousel";
import Composition from "../commons/composition";
import FeatureList from "../commons/feature_list";
import ImgTextSmall from "../commons/img-text-small";
import Paragraph2Col from "../commons/paragraph2cols";
import VideoBlok from "../commons/video-blok";
import AncoraggioBlok from "./ancoraggio-blok";
import AutoreBlok from "./autore-blok";
import CheckListBlok from "./checklist-blok";
import CitazioneBlok from "./citazione-blok";
import ImageBlok from "./image-blok";
import ImageTextFullBlok from "./image-text-full-blok";
import ParagraphBlok from "./paragraph-blok";
import ParagraphImageBlok from "./paragraph-image-blok";
import ProdottiBlok from "./prodotti-blok";
import TabellaContenutiBlok from "./tabella-contenuti-blok";
import TitoloBlok from "./titolo-blok";

const getBlokComponent = (component) => {
  // console.log(component);
  switch (component.component) {
    case "image-text-full":
      return (
        <ImageTextFullBlok component={component} key={component["_uid"]} />
      );
    case "paragraph_2_columns":
      return <Paragraph2Col blok={component} key={component["_uid"]} />;
    case "features_list":
      return <FeatureList blok={component} key={component["_uid"]} />;
    case "composition":
      return <Composition blok={component} key={component["_uid"]} />;
    case "video":
      return <VideoBlok blok={component} key={component["_uid"]} />;
    case "carousel":
      return <AutoCarousel blok={component} key={component["_uid"]} />;
    case "img-text-small":
      return <ImgTextSmall blok={component} key={component["_uid"]} />;
    case "paragraph":
      return <ParagraphBlok blok={component} key={component["_uid"]} />;
    case "image":
      return <ImageBlok blok={component} key={component["_uid"]} />;
    case "paragrafo_con_immagine":
      return <ParagraphImageBlok blok={component} key={component["_uid"]} />;
    case "citazione":
      return <CitazioneBlok blok={component} key={component["_uid"]} />;
    case "blocco_autore":
      return <AutoreBlok blok={component} key={component["_uid"]} />;
    case "ancoraggio":
      return <AncoraggioBlok blok={component} key={component["_uid"]} />;
    case "tabella_contenuti":
      return <TabellaContenutiBlok blok={component} key={component["_uid"]} />;
    case "titolo":
      return <TitoloBlok blok={component} key={component["_uid"]} />;
    case "checklist":
      return <CheckListBlok blok={component} key={component["_uid"]} />;
    case "prodotti":
      return <ProdottiBlok blok={component} key={component["_uid"]} />;
    default:
      return null;
  }
};

export { getBlokComponent };
