import React from "react";
import check from "../../resources/images/icon_check_red.svg";

const CheckListBlok = ({ blok }) => (
  <div className="my-6 lg:my-12 ci-cont-sm">
    <h4 className="ci-h4">{blok?.titolo}</h4>
    <ul className="mt-7 flex flex-col gap-5">
      {(blok?.voci ?? []).map((v) => (
        <li className="flex gap-2.5 items-center">
          <img src={check} alt="" />
          {v?.t}
        </li>
      ))}
    </ul>
  </div>
);

export default CheckListBlok;
