import React from "react";
import arr from "../../resources/images/arrow-w.svg";

const TabellaContenutiBlok = ({ blok }) => (
  <div className="my-6 lg:my-12 max-w-screen-md mx-auto px-7">
    <div className="bg-ci-beige-light p-7">
      <p className="ci-sottotitolo">{blok?.occhiello}</p>
      <h3 className="ci-h2 ci-text30 lg:ci-text40 mt-1">{blok?.titolo}</h3>
      <ul className="mt-10 flex flex-col gap-5">
        {(blok?.anchors ?? []).map((a) => (
          <li key={a?.id}>
            <a href={"#" + a?.id} className="flex items-center gap-2.5 group">
              <div className="w-6 h-6 rounded-full bg-ci-red group-hover:translate-y-1 transition-transform">
                <img src={arr} alt="" className="w-6 h-6 rotate-90" />
              </div>
              {a?.nome}
            </a>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default TabellaContenutiBlok;
