import { Link } from "gatsby";
import React from "react";
import arrIc from "../../resources/images/icon_arrow_cta.svg";
import stamp from "../../resources/images/icon_stamp@2x.png";

const Composition = ({ blok }) => {
  console.log(blok);
  const { titolo, occhiello, testo, link, testo_link, imgs } = blok;
  const [i1, i2, i3, i4, i5] = imgs;
  return (
    <section className="py-8 relative">
      <div className="flex justify-center md:absolute md:w-full z-10">
        <div className="w-full pl-7 pr-3 md:w-auto md:px-4">
          {occhiello && <p className="ci-sottotitolo">{occhiello}</p>}
          <h2 className="mt-3 ci-h2 text-3xl md:ci-text-40">{titolo}</h2>
          <p className="mt-2md:mt-4 text-sm md:text-md md:max-w-md">{testo}</p>
          {link && (
            <Link
              to={link}
              className="mt-4 flex items-center group text-sm md:text-md"
            >
              <span className="font-bold">{testo_link}</span>
              <img
                src={arrIc}
                alt=""
                className="transform transition-transform group-hover:translate-x-2"
              />
            </Link>
          )}
        </div>
      </div>
      <div
        className="relative overflow-hidden md:hidden my-7"
        style={{ height: "432px" }}
      >
        {i1 && (
          <img
            src={i1.filename}
            alt={i1.alt ?? ""}
            width={178}
            height={223}
            style={{ left: "calc(50% - 206px)" }}
            className="object-cover absolute top-0"
          />
        )}
        {i2 && (
          <img
            src={i2.filename}
            alt={i2.alt ?? ""}
            width={143}
            height={143}
            style={{ left: "calc(50% - 188px)" }}
            className="object-cover absolute bottom-0 left-0"
          />
        )}
        {i3 && (
          <img
            src={i3.filename}
            alt={i3.alt ?? ""}
            width={205}
            height={230}
            style={{ top: "116px", left: "calc(50% - 8px)" }}
            className="object-cover absolute"
          />
        )}
        <img
          src={stamp}
          alt="Calchi italiani stamp"
          className="absolute top-3"
          style={{ left: "calc(50% - 52px)" }}
          width={198}
        />
      </div>
      <div
        className="hidden md:block relative overflow-hidden"
        style={{ height: "804px" }}
      >
        {i1 && (
          <img
            src={i1.filename}
            alt={i1.alt ?? ""}
            width={355}
            height={445}
            style={{ left: "calc(50% - 694px)" }}
            className="object-cover absolute top-0"
          />
        )}
        {i2 && (
          <img
            src={i2.filename}
            alt={i2.alt ?? ""}
            width={285}
            height={285}
            style={{ left: "calc(50% - 552px)" }}
            className="object-cover absolute bottom-0"
          />
        )}
        {i3 && (
          <img
            src={i3.filename}
            alt={i3.alt ?? ""}
            width={410}
            height={460}
            style={{ top: "308px", left: "calc(50% - 110px)" }}
            className="object-cover absolute"
          />
        )}
        {i4 && (
          <img
            src={i4.filename}
            alt={i4.alt ?? ""}
            width={350}
            height={350}
            style={{ top: "115px", left: "calc(50% + 330px)" }}
            className="object-cover absolute"
          />
        )}
        {i5 && (
          <img
            src={i5.filename}
            alt={i5.alt ?? ""}
            width={350}
            height={350}
            style={{ left: "calc(50% + 728px)" }}
            className="object-cover absolute top-0"
          />
        )}
        <img
          src={stamp}
          alt="Calchi italiani stamp"
          className="absolute"
          style={{ top: "346px", left: "calc(50% - 256px)" }}
          width={198}
        />
      </div>
    </section>
  );
};

export default Composition;
