import React from "react";

const AutoCarousel = ({ blok }) => {
  const { occhiello, titolo, testo, imgs } = blok;
  return (
    <section className="my-10 lg:ci-cont">
      <div className="px-7 text-center mx-auto" style={{ maxWidth: "420px" }}>
        <p className="ci-sottotitolo">{occhiello}</p>
        <h2 className="mt-3 ci-h2 text-3xl sm:text-ci40">{titolo}</h2>
        <p className="mt-3 text-sm sm:text-base">{testo}</p>
      </div>
      <div className="mt-6 px-7 overflow-hidden slider">
        <div className="slide-track">
          {imgs.map((img) => (
            <div className="slide flex-shrink-0">
              <img
                src={img.filename}
                alt={img.alt ?? ""}
                className="h-20 sm:h-28 lg:h-36 w-auto"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AutoCarousel;
