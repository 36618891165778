import { useStaticQuery, graphql } from "gatsby";

// hooks to retrieve all shopify products in a dict by sku
const useShopifyProducts = () => {
  const res = useStaticQuery(graphql`
    query allShopiProds {
      products: allShopifyProduct {
        edges {
          node {
            variants {
              sku
              compareAtPrice
              price
              storefrontId
              title
            }
            title
            description
            handle
            images {
              altText
              src
            }
          }
        }
      }
    }
  `);
  console.log(res);

  return res.products.edges.reduce((obj, { node }) => {
    const sku = node?.handle;
    sku && (obj[sku.toUpperCase()] = node);
    return obj;
  }, {});
};

export default useShopifyProducts;
