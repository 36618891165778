import React from "react";
import RichtextBlok from "./richtext-blok";

const ParagraphBlok = ({ blok }) => (
  <div className="my-12 md:my-16 ci-cont-sm">
    <RichtextBlok data={blok.t} />
  </div>
);

export default ParagraphBlok;
