import React from "react";
import rightDec from "../../resources/images/headers/header-brown.svg";
import RichtextBlok from "./richtext-blok";
import { Link } from "gatsby";
import Overlay from "../commons/overlay";
import { responsiveGet } from "../utils";
import useWindowSize from "../use-window-size";
import ot1 from "../../resources/images/overlays/o1-top.svg";
import ob1 from "../../resources/images/overlays/o1-bot.svg";
import blg from "../../resources/images/overlays/bl-green.svg";
import blrl from "../../resources/images/overlays/bl-redlight.svg";
import brrl from "../../resources/images/overlays/br-redlight.svg";
import Button from "../button";
import ob1mob from "../../resources/images/overlays/ob1-mob.svg";
import ob1tab from "../../resources/images/overlays/ob1-tab.svg";

const ImageTextFullBlok = ({ component }) => {
  const { width } = useWindowSize();
  const img_tab = component.image_tab ? component.image_tab : component.image;
  const img_mob = component.image_mob ? component.image_mob : component.image;
  return (
    <section className="lg:flex items-center relative my-10 lg:my-24">
      <img
        src={
          responsiveGet(width, {
            mob: img_mob,
            sm: img_tab,
            md: img_tab,
            def: component.image,
          }).filename
        }
        alt={component.image.alt}
        className={
          "w-full lg:w-1/2 object-cover relative z-10 " +
          (component.image_is_left === "true"
            ? " lg:rounded-r-ci-big"
            : "lg:order-3 lg:rounded-l-ci-big")
        }
        style={responsiveGet(width, {
          def: { height: "900px", width: "45%" },
          mob: { height: "auto", width: "100%" },
          sm: { height: "auto", width: "100%" },
          md: { height: "auto", width: "100%" },
        })}
      />
      <div className="bg-ci-beige-light lg:flex-1 relative text-sm sm:text-md -mt-4 sm:-mt-8 lg:mt-0 z-0">
        <div className="px-8 pt-14 pb-28 lg:pl-24 lg:py-36 lg:pr-10 max-w-halflarge mx-auto">
          <div className="relative z-20">
            {component.sottotitolo && (
              <h3 className="font-bold text-ci-red text-sm sm:text-md mb-7">
                {component.sottotitolo}
              </h3>
            )}
            {component.titolo && (
              <h2 className="ci-h2 ci-text30 sm:ci-text40">
                {component.titolo}
              </h2>
            )}
            <RichtextBlok data={component.text} />
            {component.cta_text && (
              <div className="mt-16 sm:mt-24 text-center lg:text-left">
                <Link to="" className="inline-block">
                  <Button
                    type="green-outline"
                    className="py-4 sm:py-5 px-8 font-bold"
                  >
                    {component.cta_text}
                  </Button>
                </Link>
              </div>
            )}
            {component.signature_img && (
              <img
                src={component.signature_img.filename}
                alt={component.signature_img.alt}
                style={responsiveGet(width, {
                  def: {
                    width: "150px",
                    position: "absolute",
                    bottom: "-20px",
                    left: "200px",
                  },
                  mob: {
                    width: "100px",
                    position: "absolute",
                    bottom: "0px",
                    right: "0px",
                  },
                })}
              />
            )}
          </div>
        </div>
        <Overlay>
          <img
            src={rightDec}
            alt=""
            className="absolute"
            style={responsiveGet(width, {
              def: { top: "-1px", right: 0, width: "60%" },
            })}
          />
          <img
            src={ot1}
            alt=""
            className="absolute hidden lg:block z-10 top-0 right-0"
            style={{ width: "200%", maxWidth: "200%" }}
          />
          <img
            src={ob1}
            alt=""
            className="absolute hidden lg:block z-10 -bottom-px right-0"
            style={{ width: "200%", maxWidth: "200%" }}
          />
          <img
            src={blrl}
            alt=""
            className="absolute block sm:hidden"
            style={{
              left: "-120px",
              bottom: "-20px",
              width: "497px",
              maxWidth: "497px",
            }}
          />

          <img
            src={blg}
            alt=""
            className="absolute hidden sm:block bottom-0 right-0"
            style={responsiveGet(width, {
              def: {
                left: "-314px",
                bottom: "-120px",
                width: "740px",
              },
              sm: {
                left: "-126px",
                bottom: "-133px",
                width: "427px",
              },
              md: {
                left: "-126px",
                bottom: "-133px",
                width: "427px",
              },
            })}
          />

          <img
            src={brrl}
            alt=""
            className="absolute hidden sm:block bottom-0 right-0"
            style={responsiveGet(width, {
              def: {
                right: "-190px",
                bottom: "-10px",
                width: "900px",
              },
              sm: {
                right: "-330px",
                bottom: "-54px",
                width: "900px",
              },
              md: {
                right: "-330px",
                bottom: "-54px",
                width: "900px",
              },
            })}
          />
        </Overlay>
      </div>

      <Overlay>
        <img
          src={ob1mob}
          alt=""
          className="absolute bottom-0 w-full left-0 sm:hidden z-20"
        />
        <img
          src={ob1tab}
          alt=""
          className="absolute bottom-0 w-full left-0 hidden sm:block lg:hidden z-20"
        />
      </Overlay>
    </section>
  );
};

export default ImageTextFullBlok;
