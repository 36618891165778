import React from "react";

const TitoloBlok = ({ blok }) => (
  <div className="my-3 lg:my-6 ci-cont-sm">
    <p className="ci-sottotitolo">{blok?.occhiello}</p>
    <h3 className="ci-h2 ci-text30 lg:ci-text40 mt-1">{blok?.titolo}</h3>
  </div>
);

export default TitoloBlok;
