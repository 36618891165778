import { graphql, useStaticQuery } from "gatsby";

const useProductsDescReview = (lang = "default") => {
  const res = useStaticQuery(graphql`
    query SBProductDescPreview {
      products: allStoryblokEntry(
        filter: { field_component: { eq: "product" } }
      ) {
        edges {
          node {
            field_desc_preview_string
            slug
            lang
          }
        }
      }
    }
  `);

  return res.products.edges.reduce((obj, { node }) => {
    if (lang === node.lang) {
      obj[(node?.slug ?? "").toUpperCase()] =
        node?.field_desc_preview_string ?? "";
    }
    return obj;
  }, {});
};

export default useProductsDescReview;
