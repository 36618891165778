import React from "react";
import Overlay from "./overlay";
import ob1mob from "../../resources/images/overlays/ob1-mob.svg";
import ob1tab from "../../resources/images/overlays/ob1-tab.svg";
import ot1mob from "../../resources/images/overlays/ot1-mob.svg";
import ot1tab from "../../resources/images/overlays/ot1-tab.svg";
import ot1 from "../../resources/images/overlays/o1-top.svg";
import ob1 from "../../resources/images/overlays/o1-bot.svg";
import indicare from "../../resources/images/overlays/indicare.svg";
import maniUnite from "../../resources/images/overlays/mani-unite.svg";
import tlGreen from "../../resources/images/overlays/tl-green.svg";
import brRed2 from "../../resources/images/overlays/br-redlight2.svg";
import useWindowSize from "../use-window-size";
import { responsiveGet } from "../utils";
import { Link } from "gatsby";
import Button from "../button";

const VideoBlok = ({ blok }) => {
  const { width } = useWindowSize();
  const { titolo, occhiello, cta_link, cta_text, video, con_illustrazioni } =
    blok;
  return (
    <section className="bg-ci-beige-light relative pt-24 pb-16 sm:pt-28 sm:pb-36 lg:pt-40 lg:pb-48">
      <div className="md:ci-cont">
        {occhiello && (
          <p className="px-4 ci-sottotitolo text-sm md:text-md text-center">
            {occhiello}
          </p>
        )}
        <h2 className="px-4 mt-3 ci-h2 text-3xl md:ci-text-40 text-center">
          {titolo}
        </h2>
        <div
          className="mt-9 md:mt-16 shadow-lg mx-auto sm:rounded-ci-banner-mob"
          style={{
            width: responsiveGet(width, {
              mob: "100%",
              sm: "604px",
              md: "604px",
              def: "90%",
            }),
            maxWidth: "1024px",
          }}
        >
          <video src={video.filename} className="w-full" controls playsInline />
        </div>
        <div className="px-8 mt-9 sm:mt-14">
          <Link
            to={cta_link}
            className="w-full sm:w-auto sm:flex sm:justify-center inline-block"
          >
            <Button type="red" className="py-4 px-5 font-bold text-center">
              {cta_text}
            </Button>
          </Link>
        </div>
      </div>
      <Overlay>
        <img
          src={tlGreen}
          alt=""
          className="absolute"
          style={responsiveGet(width, {
            mob: { width: "432px", top: "-129px", left: "-143px" },
            sm: { width: "686px", top: "-210px", left: "-207px" },
            md: { width: "686px", top: "-210px", left: "-207px" },
            def: { width: "1120px", top: "-400px", left: "-350px" },
          })}
        />
        <img
          src={brRed2}
          alt=""
          className="absolute hidden lg:block bottom-0 right-0"
          style={{ width: "860px" }}
        />
        <img
          src={responsiveGet(width, {
            mob: ot1mob,
            sm: ot1tab,
            md: ot1tab,
            def: ot1,
          })}
          alt=""
          className="absolute w-full -top-px left-0 z-10"
        />
        <img
          src={responsiveGet(width, {
            mob: ob1mob,
            sm: ob1tab,
            md: ob1tab,
            def: ob1,
          })}
          alt=""
          className="absolute w-full -bottom-px left-0 z-10"
        />
        {con_illustrazioni && (
          <>
            <img
              src={maniUnite}
              alt=""
              className="absolute hidden sm:block"
              style={responsiveGet(width, {
                sm: { width: "267px", bottom: "-205px", left: "-15px" },
                md: { width: "267px", bottom: "-205px", left: "-15px" },
                def: { width: "267px", bottom: "-195px", left: "-15px" },
                xl: { width: "404px", bottom: "-143px", left: "-87px" },
                "2xl": { width: "404px", bottom: "-143px", left: "47px" },
              })}
            />
            <img
              src={indicare}
              alt=""
              className="absolute"
              style={responsiveGet(width, {
                mob: { width: "164px", top: "33px", right: "-39px" },
                sm: { width: "327px", top: "81px", right: "-164px" },
                md: { width: "327px", top: "81px", right: "-164px" },
                def: { width: "327px", top: "81px", right: "-164px" },
                "2xl": { width: "452px", top: "390px", right: "-195px" },
              })}
            />
          </>
        )}
      </Overlay>
    </section>
  );
};

export default VideoBlok;
