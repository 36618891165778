import React from "react";
import RichtextBlok from "./richtext-blok";
import avatar from "../../resources/images/icon_avatar_dark.svg";

const AutoreBlok = ({ blok }) => (
  <div className="my-6 lg:my-12 max-w-screen-md mx-auto px-7">
    <div className="bg-ci-beige-light flex p-7 gap-7 items-start justify-center flex-wrap sm:flex-nowrap">
      <div className="overflow-hidden rounded-full w-[80px] lg:w-[180px] h-[80px] lg:h-[180px] flex-shrink-0">
        <img
          src={blok?.pic?.filename ?? avatar}
          alt={blok?.pic?.alt}
          className="w-full h-full object-cover"
        />
      </div>
      <div>
        <h3 className="ci-h2 ci-text30">{blok?.autore}</h3>
        <div className="mt-3">
          <RichtextBlok data={blok?.testo} />
        </div>
      </div>
    </div>
  </div>
);

export default AutoreBlok;
