import React from "react";
import useWindowSize from "../use-window-size";
import RichtextBlok from "./richtext-blok";

const ParagraphImageBlok = ({ blok }) => {
  const { isMobile } = useWindowSize();
  const fs = blok?.a_tutta_pagina === true;
  const imgR = blok?.posizione_immagine === "destra";
  const contCN = fs ? "" : "sm:px-20 ci-cont lg:px-4";
  const imgCN = fs ? (imgR ? "pl-20" : "pr-20") : "";
  const pCN = fs
    ? "px-7 lg:max-w-halflarge lg:flex lg:px-0 " +
      (imgR ? "sm:pl-20 lg:justify-end" : "sm:pr-20")
    : "";

  return (
    <div className={"my-12 lg:my-16 " + contCN}>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-10 gap-x-4 lg:gap-x-8 items-center">
        <div className={"" + (imgR ? " lg:order-last" : "")}>
          <img
            src={blok?.immagine?.filename}
            alt={blok?.immagine?.alt}
            className="w-full"
          />
        </div>
        <div className={pCN}>
          <RichtextBlok data={blok?.testo} />
        </div>
      </div>
    </div>
  );
};

export default ParagraphImageBlok;
