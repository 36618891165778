import React from "react";
import { useContext } from "react";
import { LanguageContext } from "../../context/language-context";
import { StoreContext } from "../../context/store-context";
import Button from "../button";
import useProductsDescReview from "../use-products-desc-preview";
import useShopifyProducts from "../use-shopify-products";
import cartW from "../../resources/images/icon-cart-w.svg";
import { getTranslatedIfAvail } from "../utils";

const ProdottiBlok = ({ blok }) => {
  const allProds = useShopifyProducts();
  const allDescs = useProductsDescReview();
  const prods = blok?.lista_sku.map(
    ({ t }) => allProds[(t ?? "").toUpperCase()]
  );
  const descs = blok?.lista_sku.map(
    ({ t }, i) => allDescs[(t ?? "").toUpperCase()] || prods[i].description
  );
  const { t, locale } = useContext(LanguageContext);
  const l = locale;
  const { addVariantToCart, loading, translatedProds } =
    useContext(StoreContext);

  const addToCart = (id) => () => {
    if (!loading) addVariantToCart(id, 1);
  };

  // ENG PRODS STORE
  const getT = (sku) =>
    translatedProds[l] && translatedProds[l][sku.toLowerCase()]?.title;

  const isVert = blok?.layout === "verticale";
  const contClass = isVert
    ? "pr-7 lg:pr-0 flex flex-col items-center gap-10"
    : "pr-7 lg:pr-0 lg:max-w-full flex items-start sm:justify-center overflow-x-auto scroll-smooth pb-4 snap-mandatory snap-x hide-bar lg:snap-none";

  const prodCard = isVert
    ? "max-w-[810px] flex gap-7 items-center"
    : "snap-start flex-shrink-0 w-72 lg:w-auto lg:flex-1";

  return (
    <div className="lg:ci-cont my-6 lg:my-12 pl-7 lg:pl-0">
      <div className={isVert ? "max-w-[810px] mx-auto" : ""}>
        {blok?.occhiello && <p className="ci-sottotitolo">{blok?.occhiello}</p>}
        {blok?.titolo && (
          <h3 className="ci-h2 ci-30 sm:ci-40">{blok?.titolo}</h3>
        )}
      </div>
      <div className="overflow-hidden">
        <div className={contClass}>
          {prods.map(
            (p, i) =>
              p && (
                <div className={prodCard}>
                  <div
                    className={
                      "relative group hover:rounded-b-ci-hov overflow-hidden transition-all " +
                      (isVert ? "w-2/5 flex-shrink-0" : "")
                    }
                  >
                    <img src={p.images[1].src} alt={p.images[1]?.altText} />
                    <img
                      src={p.images[0].src}
                      alt={p.images[0]?.altText}
                      className="absolute w-full h-full top-0 left-0 object-cover opacity-100 lg:group-hover:opacity-0"
                    />
                    <button
                      className="grid place-items-center absolute bottom-2.5 left-2.5 rounded-full bg-ci-red lg:hidden disabled:opacity-60"
                      style={{ width: "46px", height: "46px" }}
                      onClick={addToCart(p.variants[0].storefrontId)}
                      disabled={loading}
                    >
                      <img
                        src={cartW}
                        alt="Aggiungi al carrello"
                        className="w-6"
                      />
                    </button>
                    <button
                      className="absolute bottom-0 left-0 hidden lg:block w-full opacity-0 group-hover:opacity-100 transition-all disabled:group-hover:opacity-60"
                      onClick={addToCart(p.variants[0].storefrontId)}
                      disabled={loading}
                    >
                      <Button type="red">
                        <span className="uppercase">
                          {t["aggiungi-carrello"]}
                        </span>
                      </Button>
                    </button>
                  </div>
                  <div className="mt-5">
                    <h3 className="ci-h3 text-xl lg:text-2xl">
                      {getTranslatedIfAvail(p.title, getT(p.handle), true)}
                    </h3>
                    <p className="mt-0.5 font-bold text-sm">
                      {getTranslatedIfAvail(p.title, getT(p.handle), false)}
                    </p>
                    <p className="mt-2 text-sm sm:text-base">{descs[i]}</p>
                    <div className="mt-4 flex gap-5 items-baseline">
                      {p.variants[0].compareAtPrice === null ? (
                        <p className="font-bold text-2xl">
                          €{p.variants[0].price}
                        </p>
                      ) : (
                        <>
                          <p className="font-bold line-through">
                            €{p.variants[0].compareAtPrice}
                          </p>
                          <p className="font-bold text-ci-red text-2xl">
                            €{p.variants[0].price}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default ProdottiBlok;
