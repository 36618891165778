import React from "react";
import RichtextBlok from "../storyblok/richtext-blok";
import Overlay from "./overlay";
import pisa from "../../resources/images/overlays/pisa.svg";
import colosseo from "../../resources/images/overlays/colosseo.svg";
import useWindowSize from "../use-window-size";
import { responsiveGet } from "../utils";

const ImgTextSmall = ({ blok }) => {
  const { img, testo, titolo, occhiello, is_home } = blok;
  const { width } = useWindowSize();

  return (
    <section
      className="my-10 sm:my-0 relative z-10"
      id={is_home ? "made-in-italy" : ""}
    >
      <div className="sm:flex px-7 pt-28 pb-10 sm:pt-44 sm:pb-44 sm:px-5 sm:gap-4 lg:gap-24 lg:px-8 lg:ci-cont lg:pt-64 lg:pb-80">
        <div className="rounded-ci-banner-mob overflow-hidden sm:flex-1">
          <img
            src={img.filename}
            alt={img?.alt ?? ""}
            className="w-full object-cover"
            style={{
              aspectRatio: responsiveGet(width, {
                mob: 315 / 200,
                sm: 356 / 400,
                md: 356 / 400,
                def: 630 / 400,
              }),
              height: responsiveGet(width, {
                mob: "200px",
                sm: "100%",
                md: "100%",
                def: "auto",
              }),
            }}
          />
        </div>
        <div className="mt-20 pt-1 sm:flex-1 sm:mt-0 sm:pt-0">
          {occhiello && <p className="ci-sottotitolo">{occhiello}</p>}
          {titolo && (
            <h2 className="ci-h2 text-3xl sm:ci-text40 mt-3">{titolo}</h2>
          )}
          <div className="mt-3">
            <RichtextBlok data={testo} />
          </div>
        </div>
      </div>
      {is_home && (
        <Overlay overflowHidden={false}>
          <img
            src={colosseo}
            alt=""
            className="absolute max-w-none"
            style={responsiveGet(width, {
              mob: { width: "652px", left: "calc(50% - 326px)", top: "-41px" },
              sm: {
                width: "913px",
                minWidth: "100%",
                left: "min(0, calc(50% - 456px))",
                top: "-35px",
              },
              md: {
                width: "913px",
                minWidth: "100%",
                left: "min(0, calc(50% - 456px))",
                top: "-35px",
              },
              def: { width: "1303px", left: 0, top: "-62px" },
            })}
          />
          <img
            src={pisa}
            alt=""
            className="absolute max-w-none"
            style={responsiveGet(width, {
              mob: { width: "864px", left: "calc(50% - 432px)", top: "201px" },
              sm: {
                width: "1344px",
                left: "calc(50% - 672px)",
                bottom: "-48px",
              },
              md: {
                width: "1344px",
                left: "calc(50% - 672px)",
                bottom: "-48px",
              },
              def: {
                width: "100%",
                left: 0,
                bottom: "86px",
              },
            })}
          />
        </Overlay>
      )}
    </section>
  );
};

export default ImgTextSmall;
