import React from "react";

const ImageBlok = ({ blok }) => (
  <div className={"my-8 md:my-16 " + blok?.a_tutta_pagina ? "" : "ci-cont"}>
    <img
      src={blok?.image?.filename}
      alt={blok?.image?.alt ?? ""}
      className="w-full"
    />
  </div>
);

export default ImageBlok;
