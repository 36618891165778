import React from "react";
import RichtextBlok from "../storyblok/richtext-blok";

const Paragraph2Col = ({ blok }) => (
  <div className="mt-20 ci-cont">
    <p className="ci-sottotitolo">{blok.occhiello}</p>
    <h2 className="mt-4 ci-h2">{blok.titolo}</h2>
    <div className="flex flex-wrap gap-7 mt-4">
      <div className="md:flex-1 w-full md:w-1/3">
        <RichtextBlok data={blok.colonna_1} />
      </div>
      <div className="md:flex-1 w-full md:w-1/3">
        <RichtextBlok data={blok.colonna_2} />
      </div>
    </div>
  </div>
);

export default Paragraph2Col;
