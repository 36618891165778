import React from "react";
import RichtextBlok from "../storyblok/richtext-blok";

const FeatureList = ({ blok }) => (
  <div className="mt-20 ci-cont">
    <p className="ci-sottotitolo">{blok.occhiello}</p>
    <h2 className="mt-4 ci-h2">{blok.titolo}</h2>
    <div className="flex flex-wrap md:flex-nowrap gap-7 mt-4">
      {(blok?.feats ?? []).map((feat) => (
        <div className="md:flex-1 w-full md:w-auto" key={feat["_uid"]}>
          <img src={feat.img.filename} alt={feat?.img?.alt ?? ""} />
          <h3 className={blok.titolo_grande ? "ci-h3 mt-5" : "font-bold mt-7"}>
            {feat.titolo}
          </h3>
          <div className="mt-10">
            <RichtextBlok
              data={feat.testo}
              ulClassName="green_check"
              pClassName="mt-0"
            />
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default FeatureList;
